<template>
  <div class="flex items-center text-gray-200 gap-1.5 justify-center text-sm font-semibold leading-none bg-black/75 rounded-full py-1 !px-2">
    <div class=" pr-1.5 border-r border-gray-500 flex" v-if="showPrivacyBadge">
      <album-privacy-badge
        :album="container"
        icon-only
      />
    </div>
    <u-tooltip :text="`${container.files_count} file(s)`" :popper="{ placement: 'top' }">
      <div class="flex items-center gap-0.5">
        <u-icon name="i-ri-image-2-line" />
        <span class="text-sm leading-none mt-px">{{container.files_count}}</span>
      </div>
    </u-tooltip>
    <u-tooltip v-if="container.albums_count !== undefined" :text="`${container.albums_count} nested album(s)`" :popper="{ placement: 'top' }">
      <div class="flex items-center gap-0.5">
        <u-icon :name="COMMON_ICONS.nestedAlbum" />
        <span class="text-sm leading-none mt-px">{{container.albums_count}}</span>
      </div>
    </u-tooltip>
  </div>
</template>

<script setup>
  const props = defineProps({
    container: Object
  });

  const {currentUserId} = useSessionStore();
  const showPrivacyBadge = computed(() => {
    const isOwner = props.container.user_id === currentUserId;
    const isSharedItem = props.container.sharedItemId;
    return props.container.isAlbum && !props.container.isNested && (isOwner || isSharedItem);
  });

</script>
